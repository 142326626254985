<template>
    <v-container fluid pa-2>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="9" mt-0 ml-2>
                    <v-select :items="events" v-model="selectedEvent"
                    outlined
                    label="Event"
                    item-text="name"                
                    ></v-select>
                </v-col>
                <v-col cols="6" mt-0 ml-2 mr-2>
                    <v-select :items="sectionOptions" v-model="selectedSection"
                    outlined
                    label="Section"
                    ></v-select>
                </v-col>
                <v-col cols="6" mt-0 ml-2 mr-2>
                    <v-select :items="slotOptions" v-model="selectedSlot"
                    outlined
                    label="Slot"
                    ></v-select>
                </v-col>

                <v-col xs2 ml-1 mt-1 align="center">
                    <div class="text-center">        
                        <v-btn icon large color="primary" @click="refresh" :loading="loading" :disabled="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                </v-col>

                <v-col cols="12">
                    
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters} from 'vuex'
import moment from 'moment-timezone'
import api from '@/store/api'

export default {
    data () {
        return {
            selectedEvent: "",
            selectedSection: "",
            selectedSlot: "",
            loading: false,
        }
    },
    mounted () {
        this.$store.dispatch("bindEvents")
    },
    computed: {
        ...mapGetters ([
            'userInfo',
            'events'
        ]),
        filteredEvents() {
            return this.events.filter(event => this.$store.getters['isAdmin'] || 
                (event.status == "Approved" && true))
        },
        eventOptions () {
            let list = []
            this.events.forEach( event => {
                list.push(event.name)
            })
            return list
        },
        sectionOptions () {
            if(this.selectedEvent == "")
                return []
            let list = []
            let self = this
            this.events.forEach( event => {
               if(self.selectedEvent = event.name){
                for (const [key, value] of Object.entries(event.slots)) {
                    list.push(value.section)
                }
               }
            })
            return list
        },
        slotOptions () {
            if(this.selectedEvent == "")
                return []
            let list = []
            let self = this
            this.events.forEach( event => {
                if(self.selectedEvent = event.name){
                    for (const [key, value] of Object.entries(event.slots)) {
                        if(self.selectedSection = value.section){
                            list.push(value.slot)
                        }
                    }
                }
            })
            return list
        },
        slotsArray () {
            let arr = []
            for (const [key, value] of Object.entries(this.item.slots)) {
                arr.push({name: key, label: value.section + ", " + value.slot, limit: value.limit, booked: value.booked, sort: value.sort})
            }
            arr = arr.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
            return arr
        },
    },
    methods: {
        refresh () {
            this.loading = true
            api.getAdminMembers(true)
            .then( () => {
                this.loading = false
            })
            .catch ( error => {
                console.log(error)
            })
        },
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        lastDay (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff < 24
        },
    }
}
</script>